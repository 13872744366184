var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title"},[_vm._v(" Editing "+_vm._s(_vm.rosterStatus.name)+" ")]),_c('p',{staticClass:"log_info"},[_vm._v(" Created by "+_vm._s(_vm.rosterStatus.createdBy ? _vm.rosterStatus.createdBy.name : '')+" on "+_vm._s(_vm.dateFormatWithTime(_vm.rosterStatus.createdAt))+" "),_c('br'),_vm._v("Last updated on "+_vm._s(_vm.dateFormatWithTime(_vm.rosterStatus.updatedAt))+" ")])])],1)],1)],1),_c('validation-observer',{ref:"rosterStatusTypeCreateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Status Type Name*","label-for":"h-roster-status-type-name","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-roster-status-type-name","placeholder":"Name","state":(errors.length > 0 || _vm.nameValidation) ? false : null,"name":"name"},on:{"input":function($event){_vm.nameValidation == true ? _vm.nameValidation = false : null}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.nameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.nameError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Abbreviation*","label-for":"h-roster-status-type-abbreviation","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Abbreviation","vid":"abbreviation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-roster-status-type-abbreviation","placeholder":"Abbreviation","state":(errors.length > 0 || _vm.abbreviationValidation) ? false : null,"name":"abbreviation"},on:{"input":function($event){_vm.abbreviationValidation == true ? _vm.abbreviationValidation = false : null}},model:{value:(_vm.abbreviation),callback:function ($$v) {_vm.abbreviation=$$v},expression:"abbreviation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.abbreviationValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.abbreviationError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Color","vid":"color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Color*","label-for":"h-roster-status-type-color","label-cols-md":"3","state":(errors.length > 0 || _vm.colorValidation) ? false : null}},[_c('b-form-input',{attrs:{"id":"h-roster-status-type-color","type":"color","state":(errors.length > 0 || _vm.colorValidation) ? false : null,"name":"color"},on:{"input":function($event){_vm.colorValidation == true ? _vm.colorValidation = false : null}},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.colorValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.colorError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Allow Assignment*","label-for":"h-roster-status-type-allowAssignment","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Allow Assignment","vid":"allowAssignment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"spaceBetweenRadio",attrs:{"id":"h-roster-status-type-allowAssignment","button-variant":"outline-primary","buttons":""},model:{value:(_vm.allowAssignment),callback:function ($$v) {_vm.allowAssignment=$$v},expression:"allowAssignment"}},_vm._l((_vm.assignmentOptions),function(assign,kindex){return _c('b-form-radio',{key:kindex,attrs:{"value":assign.value}},[(_vm.allowAssignment === assign.value)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon","size":"16"}}):_vm._e(),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(assign.text))])],1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.allowAssignmentValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.allowAssignmentError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}}),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":"Remarks","label-for":"h-event-remarks","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":"remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-event-remarks","placeholder":"Remarks","state":(errors.length > 0 || _vm.remarksValidation) ? false : null,"name":"remarks"},on:{"input":function($event){_vm.remarksValidation == true ? _vm.remarksValidation = false : null}},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.remarksValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.remarksError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-roster-status-type-status","label-cols-md":"3","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-roster-status-type-status","label":"title","options":_vm.option,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'operation-roster-status-types-index' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Changes")])],1)],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }